import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'slider-checkbox',
    templateUrl: './slider-checkbox.component.html',
    styleUrls: ['./slider-checkbox.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule],
})
export class SliderCheckboxComponent implements OnInit, OnDestroy {

  @Input() label?: string
  @Input() value: boolean = false
  @Input() parentForm!: FormGroup<any>
  @Input() controlName!: string
  @Output() onChange: EventEmitter<boolean> = new EventEmitter<boolean>()

  subscription: Subscription = new Subscription()

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {
  }

  ngOnInit(): void {
    this.parentForm.addControl(this.controlName, this.formBuilder.control(this.value, Validators.required))
    this.listenValueChanges()
  }

  listenValueChanges() {
    this.subscription.add(
      this.parentForm.get(this.controlName)?.valueChanges.subscribe(value => {
        this.onChange.emit(value)
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

}
